import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Seamless from "../components/SeamlessEmbed"
import "../css/pages/empower.scss"

const EmpowerProgram = () => {
  return (
    <Layout language="en">
      <SEO
        title="EMPOWER"
        description="Apply to be our EMPOWER Human Kindness Ambassador and win a $1,000 scholarship!"
      />

      <div className="emp-23">
        <div className="emp-23__hero">
          <img
            className="emp-23__hero-first"
            src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/2023-EMPOWER-hero-image"
            alt="two girls smiling"
          />
          <div className="emp-23__hero-logo">
            <img
              src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/EMPOWER_logo_white---brom"
              alt="empower program logo"
            />
          </div>
        </div>

        <div className="emp-23__section">
          <div className="emp-23__container">
            <div className="emp-23__grid">
              <div>
                <h1>EMPOWER Others To Be Kind</h1>
                <p>
                  When you’re kind to others, you do good and you feel good. At
                  Braces Omaha, we’re promoting kindness with our 2023{" "}
                  <b>EMPOWER Human Kindness</b> initiative.
                </p>
                <p>
                  We invite students (or those who know a student) to share
                  their stories of how they <b>EMPOWER Human Kindness</b> by
                  serving others at school or throughout our community and
                  beyond.
                </p>
                <p>
                  After the application period, we will select one deserving
                  student to be our <b>EMPOWER Human Kindness</b> Ambassador and
                  award them with a $1,000 scholarship!
                </p>

                <p>
                  By tutoring, volunteering, writing cards to service members,
                  cleaning local parks, and more, students everywhere are making
                  a difference—we want to hear about it! By encouraging students
                  to share their own experiences helping others, we promote
                  kindness and positivity throughout our community.{" "}
                </p>
              </div>

              <div>
                <div className="emp-23__bracket"></div>
                <div className="emp-23__inner-bracket">
                  <h2>
                    Teach it. Show it. <br />
                    Get Recognized.
                  </h2>

                  <p className="bold">HERE’S HOW IT WORKS:</p>
                  <p>
                    Students (Grade 1 through College) can share their stories
                    from now through November 2, 2023, with a video or written
                    submission.
                  </p>
                  <p>
                    Students, parents, teachers, coaches, and other community
                    members can nominate a student by submitting a story or
                    video.
                  </p>
                  <p>
                    After all the stories have been submitted, we will announce
                    our <b>EMPOWER Human Kindness</b> Ambassador in November on
                    World Kindness Day.
                  </p>
                </div>
                <div className="emp-23__bracket bottom"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="emp-23__section">
          <div className="emp-23__container small">
            <p>
              <strong>
                Thank you for your interest in the EMPOWER Kindness program. The
                application period is now closed. Applicants will be notified
                once the selection process has been completed.
              </strong>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EmpowerProgram
